<template>
  <div>
    <h3> {{$t('login.verificationCodePageTitle')}} </h3>
    <span> {{$t('login.changeEmailDesc')}} </span>
    <b-alert
      variant="danger"
      :show="showVerificationCodeError"
      v-html="$t('login.verificationCodeErrorMessage')"
    ></b-alert>
    <b-alert
      variant="success"
      :show="showResendBox"
      v-html="$t('login.verificationCodeResendMessage')"
    ></b-alert>
    <b-form-input
      id="confirm-input"
      name="confirm-input"
      type="number"
      :placeholder="$t('login.placeholderVerificationCode')"
      v-model.trim="verificationCode"
    ></b-form-input>
    <b-button
      block
      class="p-3 mt-3"
      type="submit"
      variant="success"
      :disabled="!verificationCode"
      @click="sendVerificationCode"
    >
      {{ $t('login.sendVerificationCode') }}
    </b-button>
<!--    <p class="text-center mt-2" style="line-height: 15px;">
      <small>
        {{ $t('login.changeEmailDesc') }}
      </small>
    </p>-->
    <p class="text-center mt-4">
      <small>
        <b-link @click="resentVerificationCode">{{ $t('login.verificationCodeResent') }}</b-link>
      </small>
    </p>
  </div>
</template>

<script>
import Auth from '@aws-amplify/auth';

export default {
  name: 'VerificationCode',
  props: {
    email: {
      type: String,
      required: true,
    },
    updateEmail: {
      type: Boolean,
    },
  },
  data() {
    return {
      verificationCode: null,
      showVerificationCodeError: false,
      showResendBox: false,
    };
  },
  methods: {
    async resentVerificationCode() {
      this.$emit('show-loader', true);
      this.showVerificationCodeError = false;
      try {
        await Auth.resendSignUp(this.email);
        this.showResendBox = true;
      } catch (error) {
        console.log('error', error);
      }
      this.$emit('show-loader', false);
    },
    async sendVerificationCode() {
      this.$emit('show-loader', true);
      this.showResendBox = false;
      let result = 0;
      try {
        if (this.updateEmail) {
          result = await Auth.verifyCurrentUserAttributeSubmit(
            'email',
            this.verificationCode,
          );
        } else {
          result = await Auth.confirmSignUp(
            this.email,
            this.verificationCode,
          );
        }
        if (result === 'SUCCESS') {
          this.$emit('verification-success', true);
        }
      } catch (error) {
        if (error.code === 'CodeMismatchException') {
          this.showVerificationCodeError = true;
        }
        this.$emit('show-loader', false);
        console.error(error);
      }
    },
  },
};
</script>

<style scoped lang="scss">
img {
  width: 33px;
  display: block;
}

.navbar {
  position: relative;

  .navbar-nav {
    height: 65px;
  }
}

.btn-link {
  color: #fff !important;
}

.card-body {
  padding: 35px;
}

small {
  display: block;
}

input[disabled="disabled"] {
  background-color: grey;
  cursor: not-allowed;
  color: #fff;
}

.password-eye {
  right: 10px;
  top: 15px;
  cursor: pointer;
}

.is-valid ~ .password-eye,
.is-invalid ~ .password-eye {
  right: 35px;
}

.sigup {
  width: 95%;
}

.businesses {
  width: 15%;
}

.imageColumn {
  display: flex;
  justify-content: center;
  background-color: #f2f2f2;
}

h5 {
  margin-bottom: 35px;
}

h3, h4 {
  font-weight: bold;
}

h3, h4, h5, h6, .password-reset, span {
  color: black;
}

.login, a {
  color: white;
}

#confirm-input {
  margin-top: 1rem;
}

.card-body {
  padding: 0;
}
</style>
