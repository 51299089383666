var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h3", [
        _vm._v(" " + _vm._s(_vm.$t("login.verificationCodePageTitle")) + " ")
      ]),
      _c("span", [_vm._v(" " + _vm._s(_vm.$t("login.changeEmailDesc")) + " ")]),
      _c("b-alert", {
        attrs: { variant: "danger", show: _vm.showVerificationCodeError },
        domProps: {
          innerHTML: _vm._s(_vm.$t("login.verificationCodeErrorMessage"))
        }
      }),
      _c("b-alert", {
        attrs: { variant: "success", show: _vm.showResendBox },
        domProps: {
          innerHTML: _vm._s(_vm.$t("login.verificationCodeResendMessage"))
        }
      }),
      _c("b-form-input", {
        attrs: {
          id: "confirm-input",
          name: "confirm-input",
          type: "number",
          placeholder: _vm.$t("login.placeholderVerificationCode")
        },
        model: {
          value: _vm.verificationCode,
          callback: function($$v) {
            _vm.verificationCode = typeof $$v === "string" ? $$v.trim() : $$v
          },
          expression: "verificationCode"
        }
      }),
      _c(
        "b-button",
        {
          staticClass: "p-3 mt-3",
          attrs: {
            block: "",
            type: "submit",
            variant: "success",
            disabled: !_vm.verificationCode
          },
          on: { click: _vm.sendVerificationCode }
        },
        [_vm._v(" " + _vm._s(_vm.$t("login.sendVerificationCode")) + " ")]
      ),
      _c("p", { staticClass: "text-center mt-4" }, [
        _c(
          "small",
          [
            _c("b-link", { on: { click: _vm.resentVerificationCode } }, [
              _vm._v(_vm._s(_vm.$t("login.verificationCodeResent")))
            ])
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }